import {
  isASuperUserLoggedIn,
  isAPotentialUserLoggedIn,
  isASupplierUserLoggedIn
} from "../utils/secure-xs/secureSS";
import { ADMIN_APP_API_PREFIX } from "./apiUtils";
import createAxiosInstance from "./axiosOptimized";

const {
  getRequest,
  postRequest,
  postFileRequest,
  putRequest,
  delRequest,
  getFileRequest
} = createAxiosInstance(ADMIN_APP_API_PREFIX);

const HT_ADMIN_OLD = "oldAdminEndpoint/";

export const getActiveProperties = (onlyDeleted, onlyInactive) =>
  getRequest(`meta/property/all?isDeleted=${!!onlyDeleted}&isInactive=${!!onlyInactive}`);
export const searchProperty = name => {
  name = encodeURIComponent(name);
  return getRequest(`admin/allUsers/properties/search?searchString=${name}`);
};
export const getCompanyCodes = () =>
  getRequest(`${HT_ADMIN_OLD}admin/reports/client/companycode/all`);
export const getCompanyCodeList = () =>
  getRequest(`${HT_ADMIN_OLD}admin/reports/list-companycodes`);

export const getPropertyImages = id => getRequest(`admin/properties/propertyImages/${id}`);
export const updatePropertyImages = (propertyId, payload) =>
  postRequest(`admin/properties/saveImagesSequence/${propertyId}`, payload);
export const getPropertyAmenities = id =>
  getRequest(`admin/properties/searchPropertyAmenities/${id}`);
export const saveProperty = property => postRequest(`admin/properties/saveProperty`, property);

export const getClientOutboundRateXML = payload =>
  postRequest(`clientinvoice/intg_audit_ari`, payload);

export const getProperty = id => getRequest(`admin/properties/${id}`);
export const getPropertyInfo = id => getRequest(`admin/client/properties/${id}/info`);
export const getPropertyCheckInHours = id => getRequest(`admin/${id}/getPropertyCheckInHours`);
export const getTaxes = payload => postRequest(`${HT_ADMIN_OLD}admin/properties/getTaxes`, payload);
export const getInboundRateXML = payload => postRequest(`admin/supplier_audit_ari`, payload);
export const getOutboundRateXML = payload => postRequest(`admin/intg_audit_ari`, payload);
export const getInboundResXML = ({
  client_confirmation_code,
  property_id,
  company_code,
  request_type,
  source_id
}) =>
  getRequest(
    `admin/reservation_payload/incoming?client_confirmation_code=${client_confirmation_code}&property_id=${property_id}&company_code=${company_code}&request_type=${request_type}&source_id=${source_id}`
  );
export const getInboundResXMLWithRoomType = ({
  client_confirmation_code,
  property_id,
  company_code,
  request_type,
  room_type_id,
  source_id
}) =>
  getRequest(
    `admin/reservation_payload_with_room_type/incoming?client_confirmation_code=${client_confirmation_code}&property_id=${property_id}&company_code=${company_code}&request_type=${request_type}&source_id=${source_id}&room_type_id=${room_type_id}`
  );
export const getOutboundResXML = ({ ht_confirmation_code, company_code, request_type }) =>
  getRequest(
    `admin/reservation_payload/outgoing?ht_confirmation_code=${ht_confirmation_code}&company_code=${company_code}&request_type=${request_type}`
  );

export const getRequestResponseJSONXMLFromKeys = (reqKey, resKey, type) =>
  getRequest(`admin/booking_logs?requestS3Key=${reqKey}&responseS3Key=${resKey}&type=${type}`);

export const getHiltonNightlyRates = supplierBookingJson =>
  postRequest(`admin/nightly_rate_hilton`, supplierBookingJson);

export const getDailyNightlyRates = htConfirmationCodes =>
  getRequest(`admin/dailyRates?htConfirmationCodes=${htConfirmationCodes}`);

export const getClientDailyNightlyRates = htConfirmationCodes =>
  getRequest(`admin/client/dailyRates?htConfirmationCodes=${htConfirmationCodes}`);
export const getDailyNightlyCancelRates = htConfirmationCodes =>
  getRequest(`admin/getCancelCharges?roomIds=${htConfirmationCodes}`);

export const getRooms = id => getRequest(`${HT_ADMIN_OLD}admin/properties/fetchRoomType/${id}`);
export const getAuditData = otaCode => getRequest(`meta/audits/${otaCode}`);

export const getMetadataAudit = (startDate, endDate) =>
  getRequest(`metadata_audit/get?startDate=${startDate}&endDate=${endDate}`);

export const getRoomAmenities = id => getRequest(`meta/roomtypes/${id}/amenities`);
export const updateRoomtypeAmenities = (id, amenities) =>
  putRequest(`meta/roomtypes/${id}/amenities/update`, amenities);
export const getRoomtypeMasterAmenities = id => getRequest(`meta/roomtypes/${id}/masterAmenities`);
export const addRoomtypeAmenity = (id, room) =>
  postRequest(`meta/roomtypes/${id}/amenities/add`, room);
export const deleteRoomtypeAmenity = roomtypeAmenityId =>
  delRequest(`meta/roomtypes/amenities/del/${roomtypeAmenityId}`);
export const getChainAndBrandFilters = () => getRequest(`meta/chains-brands`);
export const addRoom = (id, room) =>
  postRequest(`${HT_ADMIN_OLD}admin/properties/addRoomType/${id}`, room);

export const addPropertyAmenity = payload =>
  postRequest(`admin/properties/addPropertyAmenity`, payload);
export const updatePropertyAmenities = (propertyId, payload) =>
  postRequest(`admin/properties/saveOrUpdatePropertyAmenities/${propertyId}`, payload);
export const deleteAmenity = id => getRequest(`admin/properties/deleteAmenity/${id}`);
export const getMasterAmenities = id =>
  getRequest(`admin/properties/fetchMasterPropertyAmenities/${id}`);

export const outboundARISearch = reqBody =>
  postRequest(`ari/search/outbound-ari-xml-search`, reqBody);
export const inboundARISearch = reqBody =>
  postRequest(`ari/search/inbound-ari-xml-search`, reqBody);

export const searchReservationReportOld = params => {
  let path = `admin/client/searchReservationReport`;
  if (isASuperUserLoggedIn() || isASupplierUserLoggedIn()) {
    path = `admin/searchReservationReport`;
  }
  return postRequest(path, params);
};

export const uploadOfflineExcel = payload => postRequest(`admin/uploadOfflineExcel`, payload);

export const uploadDirectBills = payload => postRequest(`admin/uploadDirectBills`, payload);

export const uploadRelocatedReservations = payload =>
  postRequest(`admin/uploadRelocatedReservations`, payload);

export const uploadVccTransaction = payload => postRequest(`admin/uploadVccTransaction`, payload);

export const searchReservationReport = pathParams => {
  let path =
    isASuperUserLoggedIn() || isASupplierUserLoggedIn()
      ? `admin/reservations`
      : `admin/client/reservations`;

  return getRequest(path + pathParams);
};
export const downloadReservationReportInXlsx = pathParams =>
  getFileRequest(`admin/reservations/download${pathParams}`);

export const downloadReservationReportWithAmexRefsInXlsx = pathParams =>
  getFileRequest(`admin/reservations/amex/download${pathParams}`);

export const searchReservationReportByHtConf = conf =>
  getRequest(`admin/searchReservationReport/${conf}`); //don't forget to remove this

export const searchClientReservationReportByHtConf = conf =>
  getRequest(`admin/client/searchReservationReport/${conf}`);

export const getReservationDetailsUpstream = (htConfCode, sourceId) =>
  getRequest(`admin/getReservationDetailsUpstream?htConfCode=${htConfCode}&sourceId=${sourceId}`);

export const updateReservation = (params = {}) => postRequest("admin/updateReservation", params);

export const cancelReservationNew = req => putRequest("admin/cancelReservation", req);

export const modifyReservation = payload => putRequest(`admin/reservation`, payload);

export const modifyClientReservation = payload => putRequest(`admin/client/reservation`, payload);

export const bulkUpdateCrsAndPmsId = () => getRequest(`admin/bulkUpdateCrsAndPmsId`);

export const updateCRSAndPMSId = htConfCode =>
  getRequest(`admin/updateCRSAndPMSId?htConfCode=${htConfCode}`);

export const getFailedOutgoingRequests = (htConfCode, htRoomConfCode = null) =>
  getRequest(
    `admin/getFailedOutgoingRequests?htConfCode=${htConfCode}${
      htRoomConfCode ? `&htRoomConfCode=${htRoomConfCode}` : ""
    }`
  );

export const repushSourceFailedRequest = (resOutgoingId, sourceId) =>
  getRequest(`admin/repushSourceFailedRequest?resOutgoingId=${resOutgoingId}&sourceId=${sourceId}`);

export const repushCancelRequest = payload => postRequest(`admin/repushCancelRequest`, payload);

export const purgeReservation = payload => delRequest(`admin/purge-reservation/${payload}`);

export const modifyCRSConfNumber = payload => postRequest(`admin/modifyCRSConfNumber`, payload);

export const getFailedReservations = (params = {}) =>
  postRequest("admin/failed-reservations/get", params);

export const sendMailForFailingReservation = params =>
  getRequest(`admin/failed-reservation/email${params}`);

export const sendMailForFailedReservation = params =>
  getRequest(`admin/failed-reservations/notify/${params}`);

export const getFailedReservationsPayload = (resIncomingId, isIncoming, isGraphQL) =>
  getRequest(
    `admin/failed-reservations/payload?resIncomingId=${resIncomingId}&isIncoming=${isIncoming}&isGraphQL=${isGraphQL}`
  );

export const getResDetailsByHtConfCode = htConfCode =>
  getRequest(`admin/getReservationDetailsByHtConfCode?htConfCode=${htConfCode}`);

export const getFailedResDetailsByResIncId = (resIncomingId, isGraphQL) =>
  getRequest(
    `admin/getFailedResDetailsByResIncId?resIncomingId=${resIncomingId}&isGraphQL=${isGraphQL}`
  );

export const getReservationRequestsByClientConfCode = (clientConfCode, isGraphQL) =>
  getRequest(
    `admin/getReservationRequests?clientConfCode=${clientConfCode}&isGraphQL=${isGraphQL}`
  );

export const checkForRepushPenalty = resIncomingId =>
  getRequest(`admin/checkForRepushPenalty?resIncomingId=${resIncomingId}`);

export const repushReservation = params => postRequest(`admin/repushReservation`, params);

export const intgAuditARI = params =>
  postRequest(`${HT_ADMIN_OLD}admin/reports/intgAuditAri`, params);
export const intgAuditARIHBRes = (confirmationNumber, bookingDate) =>
  getRequest(
    `${HT_ADMIN_OLD}admin/reports/hb/reservation_xml?ht_confirmation_code=${confirmationNumber}&sale_date=${bookingDate}`
  );

export const getAllClients = () => getRequest(`meta/clients/all`);

export const getClientGroupings = () => getRequest(`meta/client/group`);
export const addAClientGroup = params => postRequest(`meta/client/group`, params);
export const updateAClientGroup = params => putRequest(`meta/client/group`, params);
export const deleteAClientGroup = id => delRequest(`meta/client/group/${id}`);

export const getAllPushClients = () => getRequest(`meta/push-clients/all`);

export const getApiConnectionDetails = () =>
  getRequest(`adminClientManagement/getApiConnectionDetails`);

export const regenerateProdQuestionnaire = prodConfigs =>
  postRequest(`adminClientManagement/regenerateProdQuestionnaire`, prodConfigs);

export const getClientById = clientId => getRequest(`adminClientManagement/getClient/${clientId}`);
export const toggleClientActive = clientId =>
  postRequest(`adminClientManagement/toggleClientActive?clientId=${clientId}`);
export const checkForDuplicateBookingUsername = (clientId, bookingUsername) =>
  getRequest(
    `adminClientManagement/checkForDuplicateBookingUsername/${clientId}/${bookingUsername}`
  );
export const addAClient = params => postRequest(`adminClientManagement/add-client`, params);
export const updateClientById = params =>
  putRequest(`adminClientManagement/updateClient/${params.id}`, params);

export const updateClientDocuments = (clientCode, files) =>
  postRequest(`adminClientManagement/uploadClientDocuments?clientCode=${clientCode}`, files);

export const getClientDocument = (clientCode, fileName) =>
  getRequest(
    `adminClientManagement/getClientDocumentUrl?clientCode=${clientCode}&fileName=${fileName}`
  );

export const generateAdminClientToken = username =>
  getRequest(`adminClientManagement/add-client/generateAdminClientToken/${username}`);
/* Client management admin apis end*/

/* Client management client apis start*/
export const generateClientToken = () => getRequest(`clientManagement/generateClientToken`);
export const getClientApiConnectionDetails = () =>
  getRequest(`clientManagement/getApiConnectionDetails`);
export const getClient = _clientId => getRequest(`clientManagement/getClient`);
export const updateClient = params => putRequest(`clientManagement/updateClient`, params);
/* Client management client apis end*/

/* Old demand client management apis start*/
export const disableAClient = code => putRequest(`clients/disable/${code}`);
export const getAClientCreds = clientCode => getRequest(`client/credentials/${clientCode}`);
export const getSourceCommission = username =>
  getRequest(`clients/${username}/clientSupplierCommissions/all`);
export const saveNewClientCreds = (params, clientCode) =>
  postRequest(`clients/add-client/${clientCode}`, params);
export const disableClientUser = id => putRequest(`admin/user/disable/${id}`);
export const updateSourceCommission = ({ username, row }) =>
  putRequest(`clients/${username}/clientSupplierCommissions/${row.supplierCode}`, row);
export const addSourceCommission = ({ username, row }) =>
  postRequest(`clients/${username}/clientSupplierCommissions`, row);
export const getAllSuppliers = () => getRequest(`suppliers/all`);
export const getDirectCommissions = user => getRequest(`clients/${user}/rateplans/all`);
export const addOrUpdateRateplans = (user, propId, plan) =>
  postRequest(`clients/${user}/property/${propId}/rateplans`, plan);
export const getBlacklistedProperties = user => getRequest(`clients/${user}/blacklistProperty`);
export const blacklistProperty = ({ user, id }) =>
  postRequest(`clients/${user}/blacklistProperty/${id}`);
export const reListProperty = ({ user, id }) =>
  putRequest(`clients/${user}/whitelistProperty/${id}`);
/* Old demand client management apis end*/

export const getUserProfile = () => getRequest(`profile`);
export const getTemporaryJWToken = () => getRequest(`temp-auth`);
export const getCacheRates = params => getRequest(`clients/search/hotels/cache/rates?${params}`);
export const searchCity = city => getRequest(`clients/search/hotels/city-suggestions/${city}`);

//Should be deleted the below endpoints?
export const getAllPolicies = propId =>
  getRequest(`${HT_ADMIN_OLD}admin/property/${propId}/policies/all`);
export const getAPolicy = (propId, user) =>
  getRequest(`${HT_ADMIN_OLD}admin/property/${propId}/policies/${user}`);
export const addAPolicy = params => postRequest(`${HT_ADMIN_OLD}admin/policies`, params);
export const updateAPolicy = params =>
  putRequest(`${HT_ADMIN_OLD}admin/policies/${params.policyCode}`, params);
export const deleteAPolicy = params =>
  delRequest(`${HT_ADMIN_OLD}admin/policies/${params.policyCode}`, params);

//Users Companies Management apis
export const getAllUsers = () => getRequest(`users/all`);
export const checkForDuplicateUsers = (userId, username) =>
  getRequest(`users/checkForDuplicateUsers/${userId}/${username}`);
export const getUserDetails = username => getRequest(`admin/get/user?username=${username}`);
export const addUser = (params, errorHandler) => postRequest(`users/user`, params, errorHandler);

export const deleteUser = params => postRequest("users/user/delete", params);

//cancel reservation apis
export const getReservation = params =>
  postRequest(`${HT_ADMIN_OLD}superuser/sucontrols/cancelReservationInfo`, params);
export const cancelReservation = htConfCode =>
  getRequest(`${HT_ADMIN_OLD}superuser/sucontrols/suCancelReservation/${htConfCode}`);

//day rate availability lookup apis
export const getDayRateProperties = () =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/getPropertyIds`);
export const getRatePlanAndRoomIds = propId =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/getRatePlanAndRoomIds?propertyId=${propId}`);
export const checkDayRate = queryParams =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/check${queryParams}`);
export const getDayRatesYearly = requestPayload => {
  if (isASuperUserLoggedIn() || isASupplierUserLoggedIn()) {
    return postRequest(`rates/dayrates`, requestPayload);
  } else {
    return postRequest(`rates/client/dayrates`, requestPayload);
  }
};

export const getDayRatesXLSX = requestPayload => {
  return postFileRequest(`rates/dayrates/download`, requestPayload);
};

export const getSearchApiRates = requestPayload => {
  let path = "rates/client/searchApi/rates";
  if (isASuperUserLoggedIn()) {
    path = "rates/searchApi/rates";
  }
  return postRequest(path, requestPayload);
};

export const getSearchApiRateXlsx = requestPayload => {
  let path = "rates/client/searchApi/rates2xlsx";
  if (isASuperUserLoggedIn()) {
    path = "rates/searchApi/rates2xlsx";
  }
  return postFileRequest(path, requestPayload);
};

export const getDayRatesYearly2csv = requestPayload =>
  postRequest(`rates/dayrates2csv`, requestPayload);
export const getDayRatesYearly2xlsx = requestPayload =>
  postFileRequest(`rates/dayrates2xlsx`, requestPayload);
export const bookReservationForDayrate = req => postRequest("rates/make-reservation", req);
export const bookReservationForQuickPriceSearch = req =>
  postRequest("rates/make-reservation-qps", req);
export const getAllRatePlansByPid = propId => getRequest(`rateplans/${propId}`);
export const getRatePlansByPid = propId => {
  if (isASuperUserLoggedIn() || isAPotentialUserLoggedIn() || isASupplierUserLoggedIn()) {
    return getRequest(`meta/rateplans/${propId}`);
  } else {
    return getRequest(`meta/client/rateplans/${propId}`);
  }
};
export const getRoomTypesByRoomTypeId = propId => getRequest(`meta/roomtypes/roomtype/${propId}`);
export const getRoomTypesByPid = propId => getRequest(`meta/roomtypes/${propId}/activerooms`);
export const getAllRoomTypesByPid = propId => getRequest(`meta/roomtypes/${propId}/allrooms`);
export const getDayRatesYearlyDemo = queryParams =>
  getRequest(`rates/dayrates/demo?${queryParams}`);
export const saveRoomType = room => postRequest(`meta/roomtypes/save`, room);

//rateplans
export const getRateplan = rateplanId => getRequest(`rateplans/rateplan/${rateplanId}`);
export const saveRateplanNew = plan => postRequest(`rateplans/rateplan/saveRatePlan`, plan);
export const saveRateplansWithOverrides = rateplans =>
  postRequest(`rateplans/saveRateplans`, rateplans);

//smartPolicy
export const addSmartPolicy = spObj =>
  postRequest(`rateplans/rateplan/addOrUpdateSmartPolicy`, spObj);
export const deleteSmartPolicy = spId =>
  postRequest(`rateplans/rateplan/deleteSmartPolicy/${spId}`);

export const saveRoomTypeNew = room => postRequest(`meta/roomtypes/roomtype/saveRoomtype`, room);
export const saveRateplan = plan => postRequest(`meta/rateplan/`, plan);
export const getPolicies = propId => getRequest(`meta/policies/${propId}`);
export const getCurrencies = () => getRequest(`meta/currencies`);
export const createDprRateplans = (propId, noOfRefDprRps, noOfNonRefDprRps) =>
  postRequest(`meta/rateplan/createDprRateplans?propertyId=${propId}
    &noOfRefDprRps=${noOfRefDprRps}&noOfNonRefDprRps=${noOfNonRefDprRps}`);

export const getRateplanClientMappings = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getReztripRatePlansByPlatform/${propertyId}`);

export const saveRateplanManagementData = (propertyId, rateplanManagementData) =>
  postRequest(`${HT_ADMIN_OLD}saveRatePlansByPlatform/${propertyId}`, rateplanManagementData);

export const getPropertyRateplans = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getRatePlansByProperty/${propertyId}`);

export const hotelbedsPropertyUpload = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getRatePlansByProperty/${propertyId}`);

export const getDistinctActiveRateCodes = () => getRequest(`rateplans/ratecodes`);

export const getAllActiveInterfaces = () => getRequest(`interfaces/all/active`);

export const getClientToRateplanMappingsByRateCode = rateCode =>
  getRequest(`rateplans/getClientMappingsByRateCode/${rateCode}`);

// delete the below one once the above is functional
export const getClientToRateplanMappingsByRateCode2 = rateCode =>
  getRequest(`${HT_ADMIN_OLD}getClientToRateplanMappingsByRateCode/${rateCode}`);

export const saveGlobalRateplanManagementData = (rateCode, rateplanManagementData) =>
  postRequest(`rateplans/saveGlobalRateplanManagementData/${rateCode}`, rateplanManagementData);

export const disableRateplan = rateplanId => postRequest(`rateplans/disableRateplan/${rateplanId}`);
export const duplicateRatePlan = (propId, rateplanId, roomTypes) =>
  postRequest(`meta/rateplan/duplicate/${propId}/${rateplanId}`, roomTypes);

//policy
export const getPolicy = policyId => getRequest(`meta/policies/policy/${policyId}`);
export const getPolicyByCrsCode = (propertyId, crsCode) =>
  getRequest(`metadata/policies?propertyId=${propertyId}&crsCode=${crsCode}`);
export const policyAddOrUpdate = policy => postRequest(`meta/policies/policy/savePolicy`, policy);
export const isPolicyTiedToRateplan = policyId =>
  getRequest(`meta/isPolicyTiedToRateplan/${policyId}`);
export const deletePolicy = policyId => delRequest(`meta/policy/${policyId}`);

export const getMetadataNotifications = queryObject =>
  postRequest(`metadata/notifications/`, queryObject);
export const getMetadataNotificationCounts = (propertyId, hours) =>
  getRequest(
    `metadata/notifications/counts?hours=${hours}${propertyId ? "&propertyId=" + propertyId : ""}`
  );
export const getNonActionedMetadataNotificationCounts = () =>
  getRequest("metadata/notifications/counts/all");
export const dismissNotification = notificationId =>
  getRequest(`metadata/notifications/${notificationId}/dismiss`);

export const snoozeNotification = reqBody => postRequest(`metadata/notification/snooze`, reqBody);
export const saveCrsValueOrOverrideAttribute = reqBody =>
  postRequest(`metadata/notifications/attributes/update`, reqBody);
export const getAttributeAuditTrail = (objectId, objectType, attribute) =>
  getRequest(
    `metadata/attributes/audit-trail?objectId=${objectId}&objectType=${objectType}&attribute=${attribute}`
  );

//Remove this when the platform tables are deleted
export const getAllPlatforms = () => getRequest(`meta/platforms/all`);
export const getAllClientsData = () => getRequest(`meta/clients/all`);
export const getAllActiveClientsData = () => getRequest(`meta/clients/allActive`);

export const exportPropertyTemplate = (propCode, exportPayload, includeHbMapping) =>
  postRequest(
    `admin/property/template/export/${propCode}?includeHbMapping=${includeHbMapping}`,
    exportPayload
  );
export const generateTgxMetadataFiles = () =>
  getRequest(`admin/property/template/generateTgxMetadataFiles`);
export const exportMetadataPushClients = reqBody =>
  postRequest(`admin/property/template/exportMetadata/pushclients`, reqBody);
export const exportRegionReport = onlyWyndhamProperties =>
  postRequest(`admin/property/template/generateRegionFile/${onlyWyndhamProperties}`);

export const downloadFile = url => getRequest(`${url}`);
export const comparePropertyTemplate = propId =>
  getRequest(`admin/property/template/comparePropertyMapping/${propId}`);
export const generateHbPortfolioAudit = reqBody =>
  postRequest(`admin/property/template/compare-property-mapping`, reqBody);

export const downloadExportProperty = filePath => getRequest(filePath);
export const importPropertyTemplate = (file, readOnly) =>
  postRequest(`admin/property/template/importFile?readOnly=${readOnly}`, file);
export const getHBMapping = propId => getRequest(`hbmapping/${propId}`);
export const auditReztripRoomtypes = (propId, file) =>
  postRequest(`admin/metadata/import/audit/reztrip?property_id=${propId}`, file).set(
    "Content-Type",
    "multipart/form-data"
  );

export const deleteRoomtypes = roomtypes =>
  delRequest(`admin/metadata/import/reztrip/roomtypes/delete_all`, roomtypes);
export const saveRoomtypes = (propId, roomtypes) =>
  postRequest(`admin/metadata/import/reztrip/roomtypes/add_all?property_id=${propId}`, roomtypes);

export const updateRoomtypes = roomtypes =>
  putRequest(`admin/metadata/import/reztrip/roomtypes/update_all`, roomtypes);
//DPR

export const fetchDPR = activeDprs => getRequest(`dpr?activeDprs=${activeDprs}`);
export const deleteDPRRateplan = dprRateplanId =>
  delRequest(`dpr/deleteDPRRateplan/${dprRateplanId}`);
export const checkForDprExistence = (dprRateplanId, dprRateplanShouldExist) =>
  getRequest(`dpr/checkForDprExistence/${dprRateplanId}/${dprRateplanShouldExist}`);
export const confirmDprExistence = (parentRateplanId, ruleId) =>
  getRequest(`dpr/confirmDprExistence/${parentRateplanId}/${ruleId}`);
export const fetchDPRHistory = dprHistoryId => getRequest(`dpr/history/${dprHistoryId}`);
export const enableDPR = rateplanId => getRequest(`rateplans/enableDpr/${rateplanId}`);
export const disableDPR = rateplanId => getRequest(`rateplans/disableDPR/${rateplanId}`);
export const associateDprRateplan = dprRp => postRequest(`dpr/associateDprRateplan`, dprRp);
export const associateDpr = dprRp => postRequest(`dpr/associateDpr`, dprRp);
export const getAllPropertiesActiveRoomsIdCodes = () =>
  getRequest("meta/roomtypes/getAllPropertiesActiveRoomsIdCodes");
export const getAllPropertiesActiveRateplansRoomtypesIdCodes = () =>
  getRequest(`meta/getAllPropertiesActiveRateplansRoomtypesIdCodes`);

export const getTaxesByTaxId = propId => getRequest(`admin/tax/${propId}`);
export const getProductsByPropertyId = propId => getRequest(`admin/property/${propId}/products`);
export const savePropertyTaxNew = tax => postRequest(`admin/tax/saveTax`, tax);

export const getProductTaxes = (rateplanId, roomtypeId) =>
  getRequest(`admin/${rateplanId}/${roomtypeId}/taxes`);
export const getTaxesByPid = propId => getRequest(`admin/${propId}/taxes`);
export const saveOrUpdatePropertyTax = tax => postRequest(`admin/taxes/saveOrUpdate`, tax);
export const deletePropertyTax = propTaxId => delRequest(`admin/taxes/delete/${propTaxId}`);

export const getAllInterfaces = () => getRequest(`getAllInterfaces/`);
export const getClientRecords = (client, status) =>
  getRequest(`getClientRecords/${client}?status=${status}`);

export const getReportDashboard = () => getRequest(`admin/dashboard/report`);
export const getAriReportDashboard = () => getRequest(`admin/dashboard/report/ari`);
export const getResTotals = filter =>
  getRequest(`admin/dashboard/report/getGrossTotalsByFilter?filter=${filter}`);

export const getAriFullPushFilters = () => getRequest(`ari/getAriFullPushFilters`);
export const getAriFullPullFilters = () => getRequest(`ari/getAriFullPullFilters`);
export const ariFullPush = reqBody => postRequest(`ari/fullPush`, reqBody);
export const ariSelectPush = reqBody => postRequest(`ari/ariSync`, reqBody);
export const ariFullPushDiss = reqBody => postRequest(`ari/fullPushDISS`, reqBody);

export const metadataFullPushDiss = reqBody => postRequest(`meta/syncAllMetadataToDISS`, reqBody);
export const marginsFullPushDiss = reqBody => postRequest(`margins/syncAllMarginsToDISS`, reqBody);
export const getNextSyncMarginToDISSResults = (reqBody, pageNumber) =>
  postRequest(`margins/getNextSyncMarginToDISSResults?pageNumber=${pageNumber}`, reqBody);
export const clientsFullPushDiss = reqBody => postRequest(`clients/syncClientsDiss`, reqBody);

export const sendDiscrepancyEmail = htConfCode =>
  getRequest(`admin/sendDiscrepancyEmail?htConfCode=${htConfCode}`);

//Audit ARI Dashboard APIs
export const getARIResults = requestPayload => postRequest("getARIResults", requestPayload);
export const getErrorARIDetails = requestPayload =>
  postRequest("getErrorARIDetails", requestPayload);

// su only API
export const getRatesInventory = params => postRequest("meta/rates-inventory", params);
export const getCategorisedRateplans = (propertyIds = []) =>
  postRequest("rateplans/categories", propertyIds);
export const getAriDeliveryInboundSummary = reqBody =>
  postRequest(`ari/audit/summaryInbound`, reqBody);
export const getAriDeliveryOutboundSummary = reqBody =>
  postRequest(`ari/audit/summaryOutbound`, reqBody);
export const getActivePropertyRateplanRoomtypes = () =>
  getRequest("meta/active-properties-rateplans-roomtypes");
export const getInboundSummary = params => postRequest("ari/audit/summaryScrollInbound", params);
export const getOutboundSummary = params => postRequest("ari/audit/summaryScrolOutbound", params);
export const getOutboundSummaryExpand = (type, params) =>
  postRequest(`ari/audit/expand?type=${type}`, params);

export const fetchInboundXML = params => postRequest("ari/audit/fetchInboundXML", params);
export const fetchOutboundHtngXML = params => postRequest("ari/audit/fetchOutboundHtngXML", params);
export const fetchOutboundHBXML = params => postRequest("ari/audit/fetchOutboundHBXML", params);

export const fetchOutboundAriRawXml = params => postRequest("admin/outbound_ari_raw", params);
export const fetchInboundAriRawXml = params => postRequest("admin/inbound_ari_raw", params);
export const getOutboundAriAudit = htConfCode =>
  getRequest(`admin/outbound_ari_audit?htConfirmationCode=${htConfCode}`);
export const getInboundAriAudit = htConfCode =>
  getRequest(`admin/inbound_ari_audit?htConfirmationCode=${htConfCode}`);

//client invoicinig

export const getMetadataActionItems = () => getRequest("meta/action-items");
export const saveAdminInvoiceChanges = reqBody =>
  postRequest("invoice/admin-save-changes", reqBody);

export const getAutoAllocatedReservation = () =>
  getRequest("clientinvoice/get-auto-allocated-reservation");
export const acceptAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-accept");
export const allocateForSelectedReservations = payload =>
  postRequest("clientinvoice/client-allocation-finalize", payload);
export const rejectAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-decline");

export const uploadInvoice = (file, invoiceId) =>
  postRequest("clientinvoice/uploadInvoice?invoiceId=" + invoiceId, file);
export const fetchAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}`, payload);
export const finalizeAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}/finalize`, payload);

export const uploadSupplierInvoice = (file, supplierInvoiceRequest) =>
  postRequest(
    "invoice/uploadSupplierInvoice?startingRow=" +
      supplierInvoiceRequest.startingRow +
      "&crsColumn=" +
      supplierInvoiceRequest.crsColumn +
      "&totalAmountColumn=" +
      supplierInvoiceRequest.totalAmountColumn +
      "&invoiceDateColumn=" +
      supplierInvoiceRequest.invoiceDateColumn,
    file
  );

// Global Controls APIs Start
export const getGlobalControls = reqBody =>
  postRequest("global-controls/fetchGlobalControls", reqBody);
export const saveGlobalControls = reqBody =>
  postRequest("global-controls/saveGlobalControls", reqBody);
// Global Controls APIs End

//Margin Management Module APIs
export const getMarginFilters = includeInActiveRp =>
  getRequest(`margins/getMarginFilters?includeInActiveRateplans=${includeInActiveRp}`);
export const getRateCodesByPropertyIds = reqBody =>
  postRequest(`meta/getPropertyRateplans`, reqBody);
export const fetchConflictingOverrides = reqBody =>
  postRequest(`margins/fetchConflictingOverrides`, reqBody);
export const editMargins = reqBody => postRequest(`margins/editMargins`, reqBody);
export const editMarginAvailability = reqBody =>
  postRequest(`margins/editMarginAvailability`, reqBody);
export const fetchMultipleMarginOverrides = reqBody =>
  postRequest(`margins/fetchMultipleMarginOverrides`, reqBody);
export const deleteOverrides = reqBody => postRequest(`margins/deleteOverrides`, reqBody);
export const saveMarginOverrides = reqBody => postRequest(`margins/saveMarginOverrides`, reqBody);
export const saveMarginsAndOverridesGlobalScreen = reqBody =>
  postRequest(`margins/saveMarginsAndOverridesGlobalScreen`, reqBody);
export const fetchClientMarginGrid = reqBody =>
  postRequest(`margins/fetchClientMarginGrid`, reqBody);
export const fetchPropertyMarginGrid = reqBody =>
  postRequest(`margins/fetchPropertyMarginGrid`, reqBody);
export const fetchRateplanMarginGrid = reqBody =>
  postRequest(`margins/fetchRateplanMarginGrid`, reqBody);
export const getClientPropertyRateplanOverrides = reqBody =>
  postRequest(`margins/getClientPropertyRateplanOverrides`, reqBody);
export const editMarginOverride = reqBody => postRequest(`margins/editMarginOverride`, reqBody);
export const fetchMarginAndOverridesSummaryCombinations = reqBody =>
  postRequest(`margins/fetchMarginAndOverridesSummaryCombinations`, reqBody);
//End of Margin Management Module APIs

export const getPushClientsQueueStatus = () => getRequest(`sqs/getQueueMessagesDetails`);

export const recallCardDetails = (htConfCode, htRoomConfCode) =>
  getRequest(`admin/card-details?htConfCode=${htConfCode}&htRoomConfCode=${htRoomConfCode}`);

export const getMaskedVccSecureCardDetails = (vccId, confirmationNumber) =>
  getRequest(`admin/vcc/getDetails/m/${vccId}/${confirmationNumber}`);
export const getUnMaskedVccSecureCardDetails = vcc =>
  postRequest(`admin/vcc/getDetailsUnMasked`, vcc);
export const getVccTransactions = vccId => getRequest(`admin/vcc/getTransactions/${vccId}`);
export const getVccHistory = vccId => getRequest(`admin/vcc/getHistory/${vccId}`);
export const getUnmaskedVccHistory = vccId => getRequest(`admin/vcc/unmaskedVccHistory/${vccId}`);
export const getSecureEmailHistory = htRoomConfCode =>
  getRequest(`admin/vcc/secureEmailHistory/${htRoomConfCode}`);
export const getNewVccAndRepush = (propertyId, htRoomConfCode) =>
  getRequest(`admin/vcc/createAndRespushRes/${propertyId}/${htRoomConfCode}`);
export const repushVcc = htRoomConfCode => getRequest(`admin/vcc/repush/${htRoomConfCode}`);
export const editVcc = (htRoomConfCode, payload) =>
  postRequest(`admin/vcc/edit/${htRoomConfCode}`, payload);
export const forceEditVcc = (htRoomConfCode, payload) =>
  postRequest(`admin/vcc/forceEdit/${htRoomConfCode}`, payload);
export const editVccAndRepush = (htRoomConfCode, payload) =>
  postRequest(`admin/vcc/editAndRespush/${htRoomConfCode}`, payload);
export const sendSecureVccEmail = (vccId, htConfCode, hotelEmailId) =>
  getRequest(`admin/vcc/sendVccSecureEmail/${vccId}/${htConfCode}/${hotelEmailId}`);

export const getReservationLogs = payload => postRequest(`admin/getReservationLogs`, payload);

// add properties
export const importHotelByCodes = payload => postRequest(`admin/importHotelByCodes`, payload);
export const importHotelByFile = payload => postRequest(`admin/importHotelByFileUpload`, payload);
export const importOfflineProperty = payload =>
  postRequest(`metadata/import-offline-property`, payload);
export const importHiltonHotelByFile = payload => postRequest(`admin/hilton/upload`, payload);
export const importPropertyGroupings = () => getRequest("admin/import-property-groupings");
export const getQueueMessageCounts = () => getRequest("admin/queue-msg-count");
export const checkIfPropertyExists = (code, crsId) => getRequest(`admin/checkIfPropertyExists?code=${code}&crsId=${crsId}`);
export const importDedgePropertyById = (code) => getRequest(`admin/importDedgePropertyById?hotelCode=${code}`);

// Synxis
export const addSynxisHotel = payload => postRequest(`admin/addNewSynxisHotel`, payload);
export const addNewSynxisHotelByFile = payload =>
  postRequest(`admin/addNewSynxisHotelUpload`, payload);
export const addSynxisHotelFromBulkFileUpload = payload =>
  postRequest(`admin/addNewSynxisHotelBulkUpload`, payload);
export const getPortfolio = () => getRequest(`admin/getPortfolioSummary`);
export const updatePropertyToggles = payload =>
  postRequest(`metadata/property-onboarding/handle-toggles`, payload);

export const getAllActiveHTRateCodes = () => getRequest("meta/getAllActiveHTRateCodes");

export const getPullClientsMetadataFilesS3BucketName = () =>
  getRequest("meta/getPullClientsMetadataFilesS3BucketName");
export const getPullClientsMetadataFilesUrlPrefix = () =>
  getRequest("meta/getPullClientsMetadataFilesUrlPrefix");

export const downloadARIExcel = propertyId =>
  postFileRequest(`ari/ari-excel/download-template?propertyId=${propertyId}`);
export const uploadARIExcel = (payload, rateOnly) =>
  postRequest(`ari/ari-excel/upload?rateOnly=${rateOnly}`, payload);

export const closeOfflineProperties = payload =>
  postRequest("ari/ari-excel/close-offline-properties", payload);

export const importWyndhamSeasonalPolicies = file =>
  postRequest(`rateplans/importSeasonalPoliciesFromFile`, file);

export const createWyndhamStopSellOverridesForSeasonalPoliciesStayDates = (file, isActive) =>
  postRequest(`margins/importStopSellFromFile?isActive=${isActive}`, file);

export const getVccSecureDetails = secureCode => getRequest(`getVccSecureDetails/${secureCode}`);

export const reSendVccSecureEmail = secureCode => getRequest(`reSendVccSecureEmail/${secureCode}`);

export const getCertificationQuestionnaireQuestions = clientCode =>
  getRequest(`adminClientManagement/getCertificationQuestionnaireQuestions/${clientCode}`);

export const approveCertificationQuestionnaire = payload =>
  postRequest("adminClientManagement/approveCertificationQuestionnaire", payload);

export const getCrs = () => getRequest(`admin/crs/all`);
